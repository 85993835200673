export * from './utils'

const GUIDE_CATEGORIES = [
  'breast',
  'thyroid',
  'lung',
  'stomach',
  'colon',
  'life',
] as const

/**
 * URL의 경로에서 가이드 카테고리를 가져온다.
 * @returns 가이드 카테고리
 */
export const getGuideCategory = (path = '') => {
  const pathname = path || window.location.pathname
  const segments = pathname.split('/').filter(Boolean) // 빈 문자열 제거
  const category = segments[0] as (typeof GUIDE_CATEGORIES)[number]
  if (!GUIDE_CATEGORIES.includes(category)) return null
  return category
}
